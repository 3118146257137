export default {
  name: "ClickonceApplications",

  data() {
    return {
      FileUploader: {
        Version: null,
        URL: null
      },
      PaperScanner: {
        Version: null,
        URL: null,
        StdURL: null
      }
    };
  },

  methods: {
  },

  async mounted() {		
    var baseHttpStClickonce = `${Settings.StaticURL}/Clickonce/`;
    
		this.FileUploader.Version = await GD_API.GetParameterValue("ESF.DocMan.FileUploader.Version");
				
		this.PaperScanner.Version = await GD_API.GetParameterValue("ESF.DocMan.FilePaperScanner.Version");
				

    // Ajuste temporal de migracion
    if (Settings.EnvironmentIsProduction)
    {
      this.PaperScanner.URL = `${baseHttpStClickonce}DocMan/FilePaperScanner/ESF.DocMan.FilePaperScanner.application`;

      this.PaperScanner.StdURL = `${baseHttpStClickonce}DocMan/FilePaperScanner/ESF.DocMan.FilePaperScanner.StandaloneInstaller.msi`;

      this.FileUploader.URL = `${baseHttpStClickonce}DocMan/FileUploader/ESF.DocMan.FileUploader.application`;
    }
    else
    {
      this.PaperScanner.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/FilePaperScanner/ESF.DocMan.FilePaperScanner.application`;

      this.PaperScanner.StdURL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/FilePaperScanner/ESF.DocMan.FilePaperScanner.StandaloneInstaller.msi`;

      this.FileUploader.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/FileUploader/ESF.DocMan.FileUploader.application`;
    }
  }

};